import { useMyContext } from 'Context/MyContextProvider';
import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';

const BillingPricingModel = ({ api, authToken, id }) => {
    const { Permisson } = useMyContext();

    const themeData = useSelector((auth) => auth.theme)
    const [priceAlert, setPriceAlert] = useState()
    const [marketingPrice, setMarketingPrice] = useState()
    const [utilityPrice, setUtilityPrice] = useState()
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState()
    const [pricingData, setPricingData] = useState([])
    const [form] = Form.useForm();

    const HandlePrice = async (e) => {
        // e.preventDefault();
        if (priceAlert || marketingPrice || utilityPrice) {
            await axios.post(`${api}pricing-model`, {
                user_id: id, priceAlert, marketingPrice, utilityPrice
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((response) => {
                if (response.data.status) {
                    message.success('Pricing Model Updated Successfully')
                }
            }).catch((error) => {
                message.error('Oops! Something Went Wrong')
                console.log(error);
            });
        }

    }

    const fetchHistory = async () => {
        setLoading(true)
        await axios.get(`${api}pricing-history/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((response) => {
                // if (response.data.status) {
                setLoading(false)
                setPricingData(parseFloat(response.data.pricing_history))
                setPriceAlert(parseFloat(response.data.pricing_history[0].price_alert))
                setMarketingPrice(parseFloat(response.data.pricing_history[0].marketing_price))
                setUtilityPrice(parseFloat(response.data.pricing_history[0].utility_price))
                // }
            })
            .catch((error) => {
                setLoading(false)
                // console.log(error);
            });
    }
    useEffect(() => {
        if (Permisson?.includes('Add or Update Pricing Model')) {
            setDisable(false)
        }
        fetchHistory();
    }, [])


    return (
        <>
            {!loading &&
                <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                    <div className='border-0 rounded p-3'
                        style={{
                            background: themeData.currentTheme === 'dark' ? '#1b2531' : '#fafafb',
                            boxShadow: 'rgba(27, 37, 49, 0.1) 0px 2px 10px' // Note: Corrected alpha value
                        }}
                    >
                        <h4 className="mb-0">Pricing Model</h4>
                        <div className={``}>
                            <div>
                                <div className="d-flex align-items-center">
                                    {/* <h5 className="mb-0 font-weight-bold">Alert Credit</h5> */}
                                </div>
                                <div className="balance-form mt-2">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form
                                                form={form}
                                                name="pricing model"
                                                layout="vertical"
                                                disabled={disable}
                                            // onFinish={(values)=>UpdateBalance(values)}
                                            >
                                                <Form.Item
                                                    label="Low credit alert"
                                                    name="price_alert"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your amount!'
                                                        }
                                                    ]}
                                                    initialValue={priceAlert}
                                                >
                                                    {/* <input type="text" value={'12'}></input> */}
                                                    <Input size="middle" controls='disabled' name="amount"
                                                        onKeyDown={(event) => {
                                                            const { key, target } = event;
                                                            if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
                                                                return;
                                                            }
                                                            if (!(/^\d$/.test(event.key) || event.key === '.')) {
                                                                event.preventDefault();
                                                            } else if (target.value.includes('.')) {
                                                                const decimalIndex = target.value.indexOf('.');
                                                                const decimalPart = target.value.substring(decimalIndex + 1);
                                                                if (decimalPart.length >= 2) {
                                                                    event.preventDefault();
                                                                }
                                                            } else {
                                                                return
                                                            }
                                                        }}
                                                        onChange={(e) => setPriceAlert(parseFloat(e.target.value))} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Marketing Price"
                                                    name="Marketing"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter Marketing amount!',
                                                        },
                                                    ]}
                                                    initialValue={marketingPrice}
                                                >
                                                    <Input
                                                        size="middle"
                                                        controls="disabled"
                                                        name="marketingPrice"
                                                        onKeyDown={(event) => {
                                                            const { key, target } = event;
                                                            if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
                                                                return;
                                                            }
                                                            if (!(/^\d$/.test(event.key) || event.key === '.')) {
                                                                event.preventDefault();
                                                            } else if (target.value.includes('.')) {
                                                                const decimalIndex = target.value.indexOf('.');
                                                                const decimalPart = target.value.substring(decimalIndex + 1);
                                                                if (decimalPart.length >= 2) {
                                                                    event.preventDefault();
                                                                }
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                        onChange={(e) => setMarketingPrice(parseFloat(e.target.value))}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Utility Price"
                                                    name="Utility"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter utility amount!',
                                                        },
                                                    ]}
                                                    initialValue={utilityPrice}
                                                >
                                                    <Input
                                                        size="middle"
                                                        controls="disabled"
                                                        name="utilityPrice"
                                                        onKeyDown={(event) => {
                                                            const { key, target } = event;
                                                            if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
                                                                return;
                                                            }
                                                            if (!(/^\d$/.test(event.key) || event.key === '.')) {
                                                                event.preventDefault();
                                                            } else if (target.value.includes('.')) {
                                                                const decimalIndex = target.value.indexOf('.');
                                                                const decimalPart = target.value.substring(decimalIndex + 1);
                                                                if (decimalPart.length >= 2) {
                                                                    event.preventDefault();
                                                                }
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                        onChange={(e) => setUtilityPrice(parseFloat(e.target.value))}
                                                    />
                                                </Form.Item>
                                                {
                                                    Permisson?.includes('Add or Update Pricing Model') &&
                                                    <Button type="primary" htmlType="submit" onClick={(e) => HandlePrice(e)}>
                                                        Submit
                                                    </Button>
                                                }
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            }
        </>
    )
}

export default BillingPricingModel
