import React, { useCallback, useMemo } from 'react';
import { ReactFlow, MiniMap, Controls, Background, useNodesState, useEdgesState, MarkerType, ConnectionLineType } from '@xyflow/react';
import '@xyflow/react/dist/style.css';

const NODE_WIDTH = 150;
const NODE_HEIGHT = 50;
const HORIZONTAL_SPACING = 200;
const VERTICAL_SPACING = 100;

const ChatbotFlowmap = ({ data }) => {
  const processNodes = useCallback((node, position = { x: 0, y: 0 }, level = 0) => {
    const keywords = JSON.parse(node.keyword);
    const label = Array.isArray(keywords) ? keywords[0] : keywords;

    const newNode = {
      id: node.id.toString(),
      position,
      data: { label },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        backgroundColor: '#f0f0f0',
        border: '1px solid #333',
        borderRadius: '5px',
        padding: '10px',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      sourcePosition: 'right',
      targetPosition: 'left',
    };

    const childNodes = [];
    const childEdges = [];

    if (node.children && node.children.length > 0) {
      const totalHeight = (node.children.length - 1) * VERTICAL_SPACING;
      const startY = position.y - totalHeight / 2;

      node.children.forEach((child, index) => {
        const childPosition = {
          x: position.x + HORIZONTAL_SPACING,
          y: startY + index * VERTICAL_SPACING,
        };

        const [childNode, ...grandChildNodes] = processNodes(child, childPosition, level + 1);
        childNodes.push(childNode, ...grandChildNodes);

        childEdges.push({
          id: `e${node.id}-${child.id}`,
          source: node.id.toString(),
          target: child.id.toString(),
          type: 'smoothstep',
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          style: { stroke: '#999' },
        });
      });
    }

    return [newNode, ...childNodes, ...childEdges];
  }, []);

  const { nodes: initialNodes, edges: initialEdges } = useMemo(() => {
    const elements = processNodes(data);
    const nodes = elements.filter(el => !el.source);
    const edges = elements.filter(el => el.source);
    return { nodes, edges };
  }, [data, processNodes]);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onInit = useCallback((reactFlowInstance) => {
    reactFlowInstance.fitView({ padding: 0.2, includeHiddenNodes: false });
  }, []);

  return (
    <div style={{ width: '100%', height: '800px' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onInit={onInit}
        fitView
        attributionPosition="bottom-left"
        minZoom={0.1}
        maxZoom={4}
        defaultZoom={0.5}
        nodesDraggable={true}
        nodesConnectable={false}
        elementsSelectable={true}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnScrollMode="free"
        connectionLineType={ConnectionLineType.SmoothStep}
      >
        <Controls />
        <MiniMap />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default ChatbotFlowmap;