import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';


const ProtectedRoute = () => {
	
	const { token } = useSelector(state => state.auth)
	// const { user } = useSelector(state => state?.auth)
	// const twoFactor = useSelector(state => state?.auth?.twoFactor)

	// useEffect(() => {
	// 	if (twoFactor) {
	// 	  console.log(twoFactor);
	// 	}
	//   }, [twoFactor]);
	if (!token) {
		// const redirectPath = `/login?redirect=${encodeURIComponent(location.pathname)}`;
		return <Navigate to={`login`} replace />;
	}
	// else if(twoFactor){
	// 	console.log('ok')
	// 	return <Navigate to={`otp-verification`} replace/>;
	// }

	return <Outlet />
}

export default ProtectedRoute