import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Radio, Row, Col } from 'antd';
import { PlusOutlined, CloseOutlined, SmileOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Picker from 'emoji-picker-react'; // Assuming you have a picker component
import Flex from 'components/shared-components/Flex';
import { ReactTransliterate } from 'react-transliterate';
import { ROW_GUTTER } from 'constants/ThemeConstant';

const { Option } = Select;

const DynamicInput = ({ label, value, onChange, placeholder, rules }) => (
    <Col xs={24} sm={24} md={24}>
        <Form.Item label={label} rules={rules}>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </Form.Item>
    </Col>
);

const CarouselCard = ({ tabKey, activeTab, data, onUpdate, language }) => {
    const [state, setState] = useState({
        phoneNumber: { button_text: '', number: '' },
        media: '',
        offerCode: { button_text: '', offer: '' },
        visitWebsite1: { button_text: '', button_url: '' },
        visitWebsite2: { button_text: '', button_url: '' },
        buttonType: '',
        subButtonType: '',
        visitWebsiteCount: 1,
        quickReply: [],
        selected: null,
        variables: [''],
        body: '',
        showPicker: false,
        quickReplyInputs: []
    });

    useEffect(() => {
        if (data) {
            onUpdate(state);
        }
    }, [state]);

    // useEffect(() => {
    //     // Reset state if tab changes
    //     if (activeTab !== index) {
    //         setState(prevState => ({
    //             ...prevState,
    //             phoneNumber: { button_text: '', number: '' },
    //             header: '',
    //             media: '',
    //             offerCode: { button_text: '', offer: '' },
    //             visitWebsite1: { button_text: '', button_url: '' },
    //             visitWebsite2: { button_text: '', button_url: '' },
    //             buttonType: '',
    //             subButtonType: '',
    //             visitWebsiteCount: 1,
    //             quickReply: [],
    //             selected: null,
    //             headerType: 'Text',
    //             language: '',
    //             variables: [''],
    //             body: '',
    //             showPicker: false,
    //             quickReplyInputs: []
    //         }));
    //     }
    // }, [activeTab]);



    const handleAddQuickReply = () => {
        if (state.quickReplyInputs.length < 9) {
            setState(prevState => ({
                ...prevState,
                quickReplyInputs: [
                    ...prevState.quickReplyInputs,
                    { placeholder: `Quick Reply ${prevState.quickReplyInputs.length + 1}`, value: '' }
                ]
            }));
        }
    };

    const handleRemoveQuickReply = (index) => {
        setState(prevState => {
            const newInputs = [...prevState.quickReplyInputs];
            newInputs.splice(index, 1);
            return { ...prevState, quickReplyInputs: newInputs };
        });
    };

    const handleQuickReplyChange = (index, value) => {
        setState(prevState => {
            const updatedInputs = [...prevState.quickReplyInputs];
            updatedInputs[index].value = value;
            return { ...prevState, quickReplyInputs: updatedInputs };
        });
    };

    const styles = {
        textarea: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '.5rem',
            outline: 'none',
        },
    };

    const onEmojiClick = (emoji) => {
        setState(prevState => ({ ...prevState, body: prevState.body + emoji.emoji }));
    };

    const togglePicker = () => {
        setState(prevState => ({ ...prevState, showPicker: !prevState.showPicker }));
    };

    const handleAddVariable = () => {
        // Extract existing variable numbers from body
        const variableRegex = /{{(\d+)}}/g;
        const existingVariables = [];
        let match;
        while ((match = variableRegex.exec(state.body)) !== null) {
            existingVariables.push(parseInt(match[1], 10));
        }

        // Determine next variable number
        const nextNumber = existingVariables.length === 0
            ? 1
            : Math.max(...existingVariables) + 1;

        const newVariable = `{{${nextNumber}}}`;

        // Update state
        setState(prevState => {
            const newVariables = [...prevState.variables, newVariable];
            const sortedVariables = [...new Set(newVariables)]
                .map((_, index) => `{{${index + 1}}}`);

            const updatedBody = `${prevState.body} ${newVariable}`;

            const reorderedBody = updatedBody.replace(variableRegex, match => {
                const number = parseInt(match.slice(2, -2), 10);
                return `{{${sortedVariables.indexOf(`{{${number}}}`) + 1}}}`;
            });
            console.log(sortedVariables)
            return {
                ...prevState,
                variables: sortedVariables,
                body: reorderedBody
            };
        });
    };

    const handleStateChange = (newState) => {
        setState(prevState => ({ ...prevState, ...newState }));
    };

    // useEffect(() => {
    //     console.log()
    // }, []);
    return (
        <div>
            <Col xs={24} sm={24} md={24}>
                <Form.Item
                    label="Card Text (Body)"
                    name={`Body_${tabKey}`} // Add tabKey to name
                >
                    <ReactTransliterate
                        renderComponent={(customProps) => (
                            <textarea
                                {...customProps}
                                rows={2}
                                className='p-2'
                                style={styles.textarea}
                                required
                                maxLength={1024}
                            />
                        )}
                        value={state.body}
                        onChangeText={(text) => handleStateChange({ body: text })}
                        lang={language}
                    />
                    <Flex gap='1rem' className='justify-content-end align-items-center'>
                        <div className='position-relative float-right d-flex' style={{ gap: '10px' }}>
                            {state.showPicker ? <CloseOutlined onClick={togglePicker} /> : <SmileOutlined onClick={togglePicker} />}
                            <span>{state.body?.length}/1024</span>
                        </div>
                        {/* <Flex className='justify-content-end'>
                            <div style={{ background: '#eee', cursor: 'pointer' }} className='p-2 rounded' onClick={handleAddVariable}>
                                <PlusOutlined />
                                <span className='ml-2'>Add variable</span>
                            </div>
                        </Flex> */}
                    </Flex>
                    <div className='position-absolute' style={{ zIndex: 99, right: '10%' }}>
                        {state.showPicker && <Picker onEmojiClick={onEmojiClick} />}
                    </div>
                    <span className='d-none'>{state.body}</span>
                </Form.Item>
            </Col>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Button Type" name={`Button_Type_${tabKey}`}> {/* Add tabKey to name */}
                        <Select
                            showSearch
                            size='default'
                            name={`button_type_${tabKey}`} // Add tabKey to name
                            defaultValue="None"
                            style={{ width: '100%' }}
                            onChange={(value) => handleStateChange({ buttonType: value })}
                        >
                            <Option value="">None</Option>
                            <Option value="quick_reply">Quick Reply</Option>
                            <Option value="call_to_action">Call To Action</Option>
                        </Select>
                    </Form.Item>
                </Col>
                {state.buttonType === 'quick_reply' && (
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label="Add More Up to 9"
                            name={`Button_Type_QuickReply_${tabKey}`} // Add tabKey to name
                            className='cursor-pointer'
                        >
                            <span className='border rounded p-2' onClick={handleAddQuickReply}>
                                <PlusOutlined />
                            </span>
                        </Form.Item>
                    </Col>
                )}
                {state.buttonType === 'call_to_action' && (
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Button Type" name={`sub_Button_Type_${tabKey}`}> {/* Add tabKey to name */}
                            <Select
                                showSearch
                                size='default'
                                style={{ width: '100%' }}
                                onChange={(value) => handleStateChange({ subButtonType: value })}
                            >
                                <Option value="choose...">Choose...</Option>
                                <Option value="visit_website">Visit Website</Option>
                                <Option value="call_phone_number">Call Phone Number</Option>
                                <Option value="copy_offer_code" disabled>Copy Offer Code</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                {state.buttonType === 'call_to_action' && state.subButtonType === 'visit_website' && (
                    <>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Number Of Buttons" name={`Button_count_${tabKey}`}> {/* Add tabKey to name */}
                                <Radio.Group onChange={(e) => handleStateChange({ visitWebsiteCount: e.target.value })} value={state.visitWebsiteCount}>
                                    <Radio value={1}>1</Radio>
                                    <Radio value={2}>2</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Row gutter={ROW_GUTTER}>
                                <Col xs={24} sm={24} md={12}>
                                    <DynamicInput
                                        label="Button 1 Text"
                                        value={state.visitWebsite1.button_text}
                                        onChange={(e) => handleStateChange({ visitWebsite1: { ...state.visitWebsite1, button_text: e.target.value } })}
                                        placeholder="Button 1 Text"
                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                        name={`visitWebsite1_button_text_${tabKey}`} // Add tabKey to name
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <DynamicInput
                                        label="Button 1 Url"
                                        value={state.visitWebsite1.button_url}
                                        onChange={(e) => handleStateChange({ visitWebsite1: { ...state.visitWebsite1, button_url: e.target.value } })}
                                        placeholder="Button 1 URL"
                                        rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                        name={`visitWebsite1_button_url_${tabKey}`} // Add tabKey to name
                                    />
                                </Col>
                                {state.visitWebsiteCount === 2 && (
                                    <>
                                        <Col xs={24} sm={24} md={12}>
                                            <DynamicInput
                                                label="Button 2 Text"
                                                value={state.visitWebsite2.button_text}
                                                onChange={(e) => handleStateChange({ visitWebsite2: { ...state.visitWebsite2, button_text: e.target.value } })}
                                                placeholder="Button 2 Text"
                                                rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                name={`visitWebsite2_button_text_${tabKey}`} // Add tabKey to name
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <DynamicInput
                                                label="Button 2 URL"
                                                value={state.visitWebsite2.button_url}
                                                onChange={(e) => handleStateChange({ visitWebsite2: { ...state.visitWebsite2, button_url: e.target.value } })}
                                                placeholder="Button 2 URL"
                                                rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                                name={`visitWebsite2_button_url_${tabKey}`} // Add tabKey to name
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                    </>
                )}
                {state.buttonType === 'call_to_action' && state.subButtonType === 'call_phone_number' && (
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item label="Phone Number" name={`phoneNumber_${tabKey}`}> {/* Add tabKey to name */}
                            <Input
                                placeholder="Phone Number"
                                value={state.phoneNumber.number}
                                onChange={(e) => handleStateChange({ phoneNumber: { number: e.target.value } })}
                            />
                        </Form.Item>
                    </Col>
                )}
                {state.buttonType === 'call_to_action' && state.subButtonType === 'copy_offer_code' && (
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item label="Offer Code" name={`offerCode_${tabKey}`}> {/* Add tabKey to name */}
                            <Input
                                placeholder="Offer Code"
                                value={state.offerCode.offer}
                                onChange={(e) => handleStateChange({ offerCode: { offer: e.target.value } })}
                            />
                        </Form.Item>
                    </Col>
                )}
                {state.buttonType === 'quick_reply' && state.quickReplyInputs.map((input, idx) => (
                    <Col xs={24} sm={24} md={12} key={idx}>
                        <Form.Item
                            label={`Quick Reply ${idx + 1}`}
                            rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                            name={`quick_reply_${tabKey}_${idx}`} // Add tabKey and idx to name
                        >
                            <Input
                                placeholder={input.placeholder}
                                value={input.value}
                                onChange={(e) => handleQuickReplyChange(idx, e.target.value)}
                                suffix={<CloseOutlined onClick={() => handleRemoveQuickReply(idx)} />}
                            />
                        </Form.Item>
                    </Col>
                ))}
            </Row>
        </div>

    );
};

export default CarouselCard;
