import { useEffect, useState } from "react";
import { useMyContext } from "Context/MyContextProvider";
import axios from "axios";
import { Spin } from "antd";
import ChatbotFlowmap from "./FlowMap";
import Flex from "components/shared-components/Flex";


const ChatBotFlow = () => {
    const { authToken, api, UserData } = useMyContext();

    const [chatbot, setChatbot] = useState([]);
    const [loading, setLoading] = useState(false);
    const ChatbotData = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}chatbot-map/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            if (data.status) {
                setChatbot(data.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }
    useEffect(() => {
        ChatbotData(UserData?.id)
    }, []);
    return (
        <>
            {loading ? (
                <Flex justifyContent="center" alignItems="center" style={{height:'80vh'}}>
                    <Spin size="large" />
                </Flex>
            ) : Object.keys(chatbot).length > 0 ? (
                <ChatbotFlowmap data={chatbot} />
            ) : null}

        </>
    );
};

export default ChatBotFlow;