import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu, Modal, Form, Row, Col } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import { Link, useNavigate } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const { Option } = Select

const getStockStatus = stockCount => {
    if (stockCount >= 10) {
        return <><Badge status="success" /><span>In Stock</span></>
    }
    if (stockCount < 10 && stockCount > 0) {
        return <><Badge status="warning" /><span>Limited Stock</span></>
    }
    if (stockCount === 0) {
        return <><Badge status="error" /><span>Out of Stock</span></>
    }
    return null
}

const categories = ['Cloths', 'Bags', 'Shoes', 'Watches', 'Devices']

const Groups = ({GetGroupNumber,ref}) => {

    const { api, authToken, UserData, navigate } = useMyContext();
    const [list, setList] = useState([])
    const [groupListData, setGroupData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)


    const GroupData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}groups/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.groups).reverse();
            setGroupData(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        GroupData()
    }, [])

    // Expose resetSelection method to parent component
    useImperativeHandle(ref, () => ({
        resetSelection: () => {
            setSelectedRowKeys([]); // Clear the selection
        }
    }));



    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'description')
        },
        {
            title: 'Total Contacts',
            dataIndex: 'contacts_count',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'contacts_count')
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     render: (_, elm) => (
        //         <Flex alignItems="center" style={{ gap: '1rem' }}>
        //             <Link to={`manage/${elm.id}`} className='d-flex h4'>
        //                 <EyeOutlined />
        //             </Link>
        //             <span className='d-flex h4 cursor-pointer'>
        //                 <DeleteOutlined />
        //             </span>
        //         </Flex>
        //     )
        // }
    ];

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };

    const onSearch = e => {
        const value = e.currentTarget.value
        const searchArray = e.currentTarget.value ? list : groupListData
        const data = utils.wildCardSearch(searchArray, value)
        setList(data)
        setSelectedRowKeys([])
    }

    const handleShowCategory = value => {
        if (value !== 'All') {
            const key = 'category'
            const data = utils.filterArray(groupListData, key, value)
            setList(data)
        } else {
            setList(groupListData)
        }
    }



    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        try {
            const response = await axios.post(`${api}create-group`, { user_id: UserData.id, name, description }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
                setConfirmLoading(false);
                handleCancel();
            }
        } catch (error) {
            setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setDescription("");
        setOpen(false);
    };
    const uncheckAllRows = () => {
        setSelectedRowKeys([]); // Clear the selected rows
    };
    useEffect(() => {
        if (selectedRowKeys) {
            // Filter the groupListData based on selectedRowKeys
            const selectedGroups = groupListData.filter(group => selectedRowKeys.includes(group.id));
            
            // Extract contacts from the selected groups
            const contacts = selectedGroups.flatMap(group => group.contacts?.map(contact => contact.number));


            GetGroupNumber(contacts);
        }
    }, [selectedRowKeys]);
    
    return (
        <Card title="Contact Groups">
            {/* <Modal
                title="Create New Role"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="Group Name" className=" my-3">
                                <Input type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Group Name"
                                />
                            </Form.Item>
                            <Form.Item label="Group Description" className=" my-3">
                                <Input type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Group Description"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal> */}
            <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                <Flex className="mb-1" mobileFlex={false}>
                    <div className="mr-md-3 mb-3">
                        <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                    </div>
                    {/* <div className="mb-3">
                        <Select
                            defaultValue="All"
                            className="w-100"
                            style={{ minWidth: 180 }}
                            onChange={handleShowCategory}
                            placeholder="Category"
                        >
                            <Option value="All">All</Option>
                            {
                                categories?.map(elm => (
                                    <Option key={elm} value={elm}>{elm}</Option>
                                ))
                            }
                        </Select>
                    </div> */}
                </Flex>
                {/* <Flex style={{ gap: '1rem' }}>
                    <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>New Campaign</Button>
                    <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>New Group</Button>
                </Flex> */}

            </Flex>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        ...rowSelection,
                    }}
                />
            </div>
        </Card>
    )
}

export default Groups
