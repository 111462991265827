import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Row, Select, Form, Col, DatePicker } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useMyContext } from 'Context/MyContextProvider';


const { RangePicker } = DatePicker;
const { Option } = Select
const Chatbot = () => {
    const { api, authToken } = useMyContext();
    // console.log(api)


    // const [users, setUsers] = useState(userData);
    const [userProfileVisible, setUserProfileVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [componentSize, setComponentSize] = useState('medium');
    const [analyticsData, setAnaLyticsData] = useState([]);
    const [users, setUsers] = useState([]);
    const [userAnaLytics, setUserAnaLytics] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },

        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, user) => (
                <div className="text-right d-flex">
                    <Tooltip title="View">
                        <Link to={`manage/${user.id}`}>
                            <Button
                                type="primary"
                                className="mr-2"
                                icon={<EyeOutlined />}
                                size="small"
                            />
                        </Link>
                    </Tooltip>
                </div>
            ),
        },
    ];







    const Userlist = () => {
        setLoading(true);
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                const data = (response.data.user).reverse();
                setUsers(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        Userlist();
        // console.log(analyticsData)
    }, []);



    const combineData = () => {
        // Create a map from usersArray with phone_number as the key

        const usersMap = {};
        users.forEach(user => {
            usersMap[user.phone_number] = user.name;
        });

        // Combine user name with analytics data
        const combinedData = analyticsData.map(analytics => {
            const userName = usersMap[analytics.phone_number] || 'Unknown';
            return { ...analytics, name: userName };
        });
        setUserAnaLytics(combinedData);
    };

    useEffect(() => {
        if (users && analyticsData) {
            combineData()
        }
    }, [users, analyticsData])


    const HandleUserChange = (value) => {
        // combineData();
        const filter = userAnaLytics.filter((item) => item.conversation_category === value)
        setFilterData(filter);
    }


    const HandleDateRangeChange = (dates) => {

        if (dates && dates.length > 0) {
            // Sort the dates in ascending order
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));

            // Format sorted dates in "dd mm yy" format
            const formattedDates = sortedDates.map(date => {
                const formattedDate = new Date(date);
                const dd = String(formattedDate.getDate()).padStart(2, '0');
                const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yy = String(formattedDate.getFullYear()).slice(-2); // Get last 2 digits of the year
                return `${dd} ${mm} ${yy}`;
            });

            // Now you can use formattedDates as the sorted date range in "dd mm yy" format
            setEndDate(formattedDates[0])
            setEndDate(formattedDates[formattedDates.length - 1])
        }





        // const startUnix = dayjs(startDate).unix
        // const endUnix = dayjs(endDate).unix();
        // const filteredData = userAnaLytics.filter(item => {

        //     const itemDateUnix = item.date
        //      return itemDateUnix >= startUnix && itemDateUnix <= endUnix;
        // });
        // console.log(filteredData);
    };
    useEffect(() => {
        if (startDate && endDate) {
        }
    }, [startDate, endDate])







    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">

                    <h2>Chatbot</h2>
                </Flex>
            </div>
            <div className='container-fluid'>
                <Row>
                    <Col span={24}>
                        <Form
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                            <Form.Item>
                                <Row style={{ gap: '1rem' }}>
                                    <Col span={3}>

                                        <Form.Item label="Username">
                                            <Select
                                                showSearch
                                                size={'default'}
                                                defaultValue={{ key: "choose..." }}
                                                style={{ width: '100%' }}
                                                onChange={(value) => HandleUserChange(value)}
                                            >
                                                {
                                                    users.length > 0 ?
                                                        users.map((item, index) => (
                                                            <Option value={item.name} key={index}>{item.name}</Option>
                                                        ))
                                                        :
                                                        <Option >No Users Found</Option>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Date Range">
                                            <RangePicker
                                                onChange={(dates) => HandleDateRangeChange(dates)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div className="table-responsive">
                <Table loading={loading} columns={tableColumns} dataSource={filterData?.length > 0 ? filterData : users} rowKey="id" />
            </div>
            {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
        </Card >
    );
};

export default Chatbot
