import { useMyContext } from 'Context/MyContextProvider';
import { Button, DatePicker, Modal, Result, Table, Tag, Tooltip, message } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { EyeOutlined, ClockCircleOutlined, DeleteOutlined, PlayCircleOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
const { confirm } = Modal;
const ScheduleCampainTable = ({ limit, filter }) => {
    const { api, authToken, UserData } = useMyContext();
    const [loading, setLoading] = useState(false);
    const [campaigns, setCampaigns] = useState([])
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [editId, setEditId] = useState('');
    const [open, setOpen] = useState(false);
    const [campaignReport, setCampaignReport] = useState([]);
    const GetCampaignData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}get-schedule-campaign/${UserData.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = (response.data.campaign).reverse();
            if (filter) {
                const filterData = data?.filter((item)=>item.status===filter);
                setCampaigns(filterData);
            }else{
                setCampaigns(data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            // console.log(error);
        }
    }
    useEffect(() => {
        GetCampaignData();
    }, [])

    const getShippingStatus = status => {
        if (status === 'pending') {
            return 'blue'
        }
        if (status === 'Completed') {
            return 'cyan'
        }
        return ''
    }
    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.type.length - b.type.length,
            },
        },
        {
            title: 'Numbers',
            dataIndex: 'Numbers',
            render: (_, record) => (record?.numbers?.split(',').length),
            sorter: {
                // compare: (a, b) => a..length - b..length,
            },
        },
        {
            title: 'Schedule Date',
            dataIndex: 'schedule_at',
            render: (_, record) => (<span>{record?.schedule_date} | {record?.schedule_time}</span>),
            sorter: {
                compare: (a, b) => a.template_name.length - b.template_name.length,
            },
        },
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            sorter: {
                compare: (a, b) => a.template_name.length - b.template_name.length,
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => (
                <><Tag color={getShippingStatus(record.status)}>{record.status}</Tag></>
            ),
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        ...(!filter
            ? [
                {
                  title: 'Created Date',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
                  render: (created_at) => {
                    const date = new Date(created_at);
                    return date.toLocaleDateString(); // Adjust this line to format the date as needed
                  },
                },
              ]
            : []),
       
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    <Tooltip title="View">
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<EyeOutlined />}
                            size="small"
                            onClick={() => showModal(item.id)}
                        />
                    </Tooltip>
                    <Tooltip title="reschedule">
                        <Button
                            type="default"
                            className="mr-2"
                            icon={<ClockCircleOutlined />}
                            size="small"
                            onClick={() => showScheduleModel(item.id)}
                        />
                    </Tooltip>
                    <Tooltip title={`${item.status === 'pending' ? 'Stop' : item.status === 'hold' && 'Resume'} Campaign`}>
                        <Button
                            disabled={!(item.status === 'pending' || item.status === 'hold')}
                            type='default'
                            className="mr-2"
                            icon={item.status === 'pending' ? <PauseCircleOutlined /> :
                                item.status === 'hold' ? <PlayCircleOutlined /> :
                                    <StopOutlined />
                            }
                            size="small"
                            onClick={() => showStatusConfirm(item.id, item.status)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button
                            danger
                            className="mr-2"
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={() => showConfirm(item.id)}
                        />
                    </Tooltip>

                </div>
            ),
        }
    ];

    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this campaign?',
            content: "By deleting the campaign, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleDelete(id)
            },
            onCancel() {
                //  console.log('Cancel');
            },
        });
    }
    // const chekScheduleTime=(date,time)=>{
    //     console.log(date,time);
    //     return
    // }
    function showStatusConfirm(id, status) {
        confirm({
            title: `Do you want to ${status === 'pending' ? 'pause' : status === 'hold' && 'resume'} this campaign?`,
            content: `You can ${status === 'pending' ? 'pause' : status === 'hold' && 'resume'} the campaign by doing the same.`,
            onOk() {
                let newStatus = status === 'pending' ? 'hold' : 'pending';
                HandlePlayPause(id, newStatus)
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }


    const showModal = async (id) => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}get-schedule-campaign-report/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            setCampaignReport(response.data.campaign);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            // console.log(error);
        }
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const innerTableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.mobile_number.length - b.mobile_number.length,
            },
        },
        {
            title: 'Number',
            dataIndex: 'mobile_number',
            sorter: {
                compare: (a, b) => a.mobile_number.length - b.mobile_number.length,
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => (
                text === 'sent' ? <FontAwesomeIcon icon={faCheck} /> :
                    text === 'delivered' ? <FontAwesomeIcon icon={faCheckDouble} /> :
                        text === 'read' ? <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#22af4b' }} /> :
                            <Tooltip title="Message failed to send because more than 24 hours have passed since the customer last replied to this number.">
                                <Button className='p-0 border-0'>
                                    <FontAwesomeIcon icon={faCircleExclamation} className='text-danger' />
                                </Button>
                            </Tooltip>
            ),
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
            render: (created_at) => {
                const date = new Date(created_at);

                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);

                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12 || 12;

                return `${day}/${month}/${year} | ${hours}:${minutes}:${seconds} ${ampm}`;
            },
        },

    ];

    const [openScheduleModel, setOpenSchedule] = useState(false);
    const showScheduleModel = async (id) => {
        setOpenSchedule(true);
        setEditId(id);
    };
    const handleCancelScheduleModel = () => {
        setOpenSchedule(false);
    };
    const HandleScheduleTime = (value) => {
        const { $d } = value;
        const year = $d.getFullYear();
        const month = $d.getMonth() + 1;
        const day = $d.getDate();
        const hours = $d.getHours();
        const minutes = $d.getMinutes();
        // const seconds = $d.getSeconds();
        setScheduleDate(`${year}-${month}-${day}`);
        setScheduleTime(`${hours}:${minutes}:00`);
    }
    // final submit 
    const HandleSchedule = async () => {
        const campaignResponse = await axios.post(`${api}reschedule-campaign/${editId}`, {
            id: editId,
            schedule_date: scheduleDate,
            schedule_time: scheduleTime,
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            handleCancelScheduleModel()
            GetCampaignData()
            message.success(campaignResponse.data.message)
        }
    }
    const HandlePlayPause = async (id, status) => {
        const campaignResponse = await axios.post(`${api}change-status-sc/${id}`, {
            id: id,
            status: status
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            GetCampaignData()
            message.success(campaignResponse.data.message)
        }
    }
    const HandleDelete = async (id) => {
        const campaignResponse = await axios.delete(`${api}delete-schedule-campaign/${id}`, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            handleCancelScheduleModel()
            GetCampaignData()
            message.success(campaignResponse.data.message)
        }
    }

    const defaultValue = moment('2024-01-01');
    return (
        <>
            <Modal
                title="Real time updates"
                open={open}
                onCancel={handleCancel}
                footer={null}
                width={900}
            >

                <Table loading={loading} columns={innerTableColumns} dataSource={campaignReport} rowKey="id" />
            </Modal>

            {/* schedule model  */}
            <Modal
                title="Reschedule Campaign"
                open={openScheduleModel}
                destroyOnClose
                defaultValue={defaultValue}
                onCancel={handleCancelScheduleModel}
                footer={null}
            >
                <Result
                    status="success"
                    title="Select date and Time"
                    extra={
                        <>
                            <DatePicker
                                disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(value) => HandleScheduleTime(value)} />
                            <Link to={``}>
                                <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                                    Reschedule Campaign
                                </Button>
                            </Link>
                        </>
                    }
                />
            </Modal>
            <Table loading={loading} columns={tableColumns} dataSource={campaigns} rowKey="id" pagination={{ pageSize: limit }} />
        </>
    )
}

export default ScheduleCampainTable