import { Alert, Button, Card, Carousel, Checkbox, Col, DatePicker, Form, Input, message, Modal, Progress, Radio, Result, Row, Select, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'

import { useMyContext } from 'Context/MyContextProvider';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import Flex from 'components/shared-components/Flex';
import "react-transliterate/dist/index.css";

import CarouselPreview from '../carouselPreview';
import MediModel from '../../Campaign/MediModel';

const CarouselCampaign = () => {
    const { UserData, api, authToken, templates, Permisson, navigate, userBalance } = useMyContext()
    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [template, setTemplate] = useState({ body: null });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);

    const [singleLineNumber, setSingleLineNumber] = useState([])
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [templateCategory, setTemplateCategory] = useState();
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [openScheduleModel, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initialTemplates, setInitialTemplates] = useState([]);
    const [mainBody, setMainBody] = useState(null);

    const [mainBodyInputs, setMainBodyInputs] = useState([]);

    const [cardData, setCardData] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [cards, setCards] = useState();

    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked

            const formatNumber = (number) => {
                const digits = number.toString();
                if (digits.length === 12) {
                    return digits;
                } else if (digits.length === 10) {
                    return '91' + digits;
                } else {
                    // Handle other cases if needed
                    return null; // Or throw an error
                }
            };
            // Extract and format numbers from serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);

            // Extract and format numbers from userBlocked
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);


            // Combine both arrays into one
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetBlockNumber()
    }, [])


    useEffect(() => {
        if (templates.length > 0) {
            const carouselTemplates = templates?.filter(template =>
                template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setInitialTemplates(carouselTemplates)
            setTemplateList(carouselTemplates)
        }
    }, [templates])

    useEffect(() => {
        if (templateCategory) {
            const filteredList = initialTemplates.filter(item => item.category === templateCategory);
            setTemplateList(filteredList);
        } else {
            setTemplateList(initialTemplates);
        }
    }, [templateCategory, initialTemplates]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };




    useEffect(() => {
        // console.log(number)
        if (number) {
            setNumberError(false)
        }
    }, [number])



    const HandleTemplate = (value) => {
        setMediaLink('');
        setTemplateName(value);
        setTemplateKey((prevKey) => prevKey + 1);

        // Find the selected template
        const template = templates?.find((item) => item.name === value);

        // Set template-related state
        setTemplateLanguage(template?.language);
        setTemplatePreview(template);

        // Extract main body and dynamic values
        const { templateCards, mainBodyTemp } = extractTemplateSections(template);
        setMainBody(mainBodyTemp);
        setCards(templateCards)

        const mainBodyDynamicValue = mainBodyTemp?.text?.match(/{{\d+}}/g);
        if (mainBodyDynamicValue) {
            const inputs = generateInputsForDynamicValues(mainBodyDynamicValue, 'mainBody');
            setMainBodyInputs(inputs);
        } else {
            setMainBodyInputs([]);
        }

        // Handle templateCards
        if (templateCards && templateCards.length > 0) {
            const cardData = templateCards.map((card, index) => {
                const cardBodyDynamicValue = card.body?.text?.match(/{{\d+}}/g);
                // Return the card with dynamic inputs and media-related fields
                return {
                    ...card,
                    inputs: cardBodyDynamicValue
                        ? generateInputsForDynamicValues(cardBodyDynamicValue, 'card', index)
                        : [],
                    mediaType: 'Media', // Initialize mediaType
                    mediaLink: '', // Initialize mediaLink
                    mediaName: '', // Initialize mediaName
                    useExternalUrl: false, // Initialize useExternalUrl
                };
            });
            setCardData(cardData); // Set cardData with media-related fields
        } else {
            setCardData([]);
        }
    };




    const extractTemplateSections = (template) => {
        if (template) {
            let mainBodyTemp = null;
            let templateCards = [];

            // Process components to extract main body and carousel cards
            template.components.forEach(section => {
                if (section.type === "BODY") {
                    mainBodyTemp = section;
                } else if (section.type === "CAROUSEL") {
                    templateCards = section.cards.map(card => {
                        let cardHeader = null;
                        let cardBody = null;
                        let cardButtons = null;

                        // Extract header, body, and buttons for each card
                        card.components.forEach(cardSection => {
                            switch (cardSection.type) {
                                case "HEADER":
                                    cardHeader = cardSection;
                                    break;
                                case "BODY":
                                    cardBody = cardSection;
                                    break;
                                case "BUTTONS":
                                    cardButtons = cardSection;
                                    break;
                                default:
                                    break;
                            }
                        });

                        return { header: cardHeader, body: cardBody, buttons: cardButtons };
                    });
                }
            });
            return { mainBodyTemp, templateCards }
            // setMainBody(mainBodyTemp);
            // setCarouselCards(cards);
        }
    }

    const generateInputsForDynamicValues = (dynamicValues, type, cardIndex) => {
        return dynamicValues.map((placeholder, index) => (
            <Input
                key={index}
                type="text"
                placeholder={`Enter value for ${placeholder}`}
                onChange={(e) => handleInputChange(index, e.target.value, type, cardIndex)}
            />
        ));
    }

    const handleInputChange = (index, value, type, cardIndex) => {
        if (type === 'mainBody') {
            setDynamicValues(prevValues => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });
        } else if (type === 'card') {
            setCardData(prevCards => {
                const updatedCards = [...prevCards];
                updatedCards[cardIndex].inputs[index] = value;
                return updatedCards;
            });
        }
    };


    const HandleDynamicValues = (templatePreview) => {
        if (dynamicValues.length > 0) {
            const text = templatePreview?.components[1]?.text;
            let updatedText = text;
            dynamicValues.forEach((item, index) => {
                updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
            });
            setNewMessage(updatedText); // Log the updated text array
        }
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview)
    }, [templatePreview, dynamicValues])





    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                body: null,
            });

        }
    }, [requestType])




    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
        // console.log(numbers)
    }, [numbers])





    const showModal = async () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    // for media model 

    const showMediaModel = (index) => {
        setCurrentCardIndex(index);  // Set the current card index
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };

    // for media model 
    const CreditCalculation = async (numberCount) => {
        try {
            const MP = UserData?.pricing_model?.marketing_price;
            return numberCount * MP >= userBalance
        } catch (error) {
            console.error(error);
        }
    }

    const showScheduleModel = async () => {
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        let CreditNotAvailable = await CreditCalculation(filteredNumbers?.length)
        if (CreditNotAvailable) {
            showModal()
        } else {
            setOpenSchedule(true);
        }
    };

    const handleCancelScheduleModel = () => {
        setOpenSchedule(false);
    };


    const buttonStyle = {
        background: 'linear-gradient(116deg,  #40e495, #30dd8a, #2bb673)',
        color: 'white',
        border: 'none'
    }

    const HandleUploadImage = (path, name) => {
        if (path) {
            // Update the specific card data
            setCardData((prevData) =>
                prevData.map((card, index) =>
                    index === currentCardIndex
                        ? { ...card, mediaLink: path, mediaName: name }
                        : card
                )
            );
            setOpenMediaModel(false);
        }
    };

    const HandleScheduleTime = (value) => {
        const { $d } = value;
        const year = $d.getFullYear();
        const month = $d.getMonth() + 1;
        const day = $d.getDate();
        const hours = $d.getHours();
        const minutes = $d.getMinutes();
        // const seconds = $d.getSeconds();
        setScheduleDate(`${year}-${month}-${day}`);
        setScheduleTime(`${hours}:${minutes}:00`);
    }


    const filterNumbers = (numbers, blockNumbers) => {
        const uniqueNumbersSet = [...new Set(numbers)];
        // const numbersArray = number.split(',').map(number => number.trim());
        const numbersArray = number.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
        // console.log(numbersArray);return
        const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
        // console.log(combinedNumbers)
        const finalFilteredNumbers = combinedNumbers.filter(number => {
            const num = parseInt(number);
            return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
        }).map(number => {
            const num = parseInt(number);
            return num.toString().length === 10 ? '91' + num : number;
        });
        const filteredNumbers = finalFilteredNumbers.filter(finalNumber => !blockNumbers.includes(finalNumber));
        return filteredNumbers;
    };
    // final submit 

    const HandleSchedule = async () => {
        setLoading(true)
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        // return
        const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
            name,
            user_id: UserData.id,
            numbers: filteredNumbers,
            campaign_type: requestType,
            custom_text: customMessage,
            template_name: templateName,
            header_type: template.header?.format,
            header_media_url: mediaLink,
            body_values: dynamicValues,
            button_type: template?.buttons?.buttons[0].type,
            button_value: dynamicButtonUrl,
            schedule_date: scheduleDate,
            schedule_time: scheduleTime,
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            navigate('/campaign')
        }
        setLoading(false)
    }

    // send message code start from here 
    const generateContent = (type, id) => {
        switch (type) {
            case 'IMAGE':
                return { image: { id: id } };
            case 'VIDEO':
                return { video: { id: id } };
            case 'DOCUMENT':
                return { document: { id: id } };
            default:
                return {};
        }
    };
    const [isSending, setIsSending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [rateLimit, setRateLimit] = useState();
    // Function to generate the payload for each message
    const generatePayload = (item, templateName, templateLanguage, dynamicValues) => {
        return {
            messaging_product: "whatsapp",
            to: item,
            type: "template",
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                    //  ...(bodySection ? [bodySection] : []), // Include body section only if it exists
                    {
                        type: "CAROUSEL",
                        cards: cardData.map((card, cardIndex) => ({
                            card_index: cardIndex,
                            components: [
                                ...(card.header ? [
                                    {
                                        type: "HEADER",
                                        parameters: [
                                            {
                                                type: (() => {
                                                    switch (card.header?.format) {
                                                        case "IMAGE":
                                                            return "IMAGE";
                                                        case "VIDEO":
                                                            return "VIDEO";
                                                        case "DOCUMENT":
                                                            return "DOCUMENT";
                                                        default:
                                                            return "TEXT";
                                                    }
                                                })(),
                                                ...generateContent(card.header?.format, card.mediaLink),
                                            },
                                        ],
                                    },
                                ] : []),
                                ...(card.buttons && card.buttons.length > 0 ? card.buttons.map((button, buttonIndex) => ({
                                    type: "BUTTON",
                                    sub_type: button.type === "QUICK_REPLY" ? "QUICK_REPLY" : "URL",
                                    index: buttonIndex,
                                    ...(button.type === 'URL' && dynamicButtonUrl?.length ? {
                                        parameters: dynamicButtonUrl.map(url => ({ type: "text", text: url }))
                                    } : {}),
                                    ...(button.type === 'QUICK_REPLY' ? {
                                        parameters: [{ type: "payload", payload: "btntwo" }]
                                    } : {}),
                                })) : []),
                            ],
                        })),
                    }
                ]
            },
        }
    };

    const resetCampaign = () => {
        setProgress()
        setIsModalVisible(false)
        setSingleLineNumber([])
        setNumber('')
    }


    const conicColors = {
        '0%': '#16702F',
        // '50%': '#ffe58f',
        '100%': '#34B55A',
    };

    const handleMediaLinkChange = (value, index) => {
        // console.log(value,index)
        const newMediaLink = value;
        setCardData((prevData) =>
            prevData.map((card, i) =>
                i === index ? { ...card, mediaLink: newMediaLink } : card
            )
        );
    };

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (showAlert) {
            message.error('Please provide the required media link and media type.');
        }
    }, [showAlert]);

    // Main handler function
    const ParepareRequest = async (e) => {
        e.preventDefault();
        const missingCardIndices = cardData
            .map((card, index) => card.mediaType === 'Media' && !card.mediaLink ? index + 1 : null)
            .filter(index => index !== null);

        if (missingCardIndices.length > 0) {
            // Create a message listing all problematic card numbers
            const cardNumbers = missingCardIndices.join(', ');
            message.error(`Cards ${cardNumbers} are missing media files. Please select and upload files for these cards.`);
            return; // Prevent form submission if there are errors
        }
        else if (templateName === '') {
            setError('You have to select a template first');
            return;
        }
        try {
            // Generate message payloads
            const messagePayloads = generatePayload(
                ':number:', templateName, templateLanguage, dynamicValues
            );
            HandleSubmit(messagePayloads)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const HandleSubmit = async (payload) => {
        try {
            const response = await axios.post(`${api}save-preset`, {name : name ,user_id : UserData?.id, object : payload }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                navigate('/carousel/Preset')
            }
        } catch (error) {
            
        }finally {

        }
    }

    return (
        <>
            {/* for belance alert  */}
            <Modal
                title="Insufficient Credits"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Result
                    title="Insufficient Credits to run this campaign :("
                    extra={
                        <Link to={`/users/manage/${UserData.id}/billing`}>
                            <Button type="primary" key="console">
                                Add Credits
                            </Button>
                        </Link>
                    }
                />
            </Modal>

            {/* schedule model  */}
            <Modal
                title="Schedule Campaign"
                open={openScheduleModel}
                onCancel={handleCancelScheduleModel}
                footer={null}
            >
                <Result
                    status="success"
                    title="Select date and Time"
                    extra={
                        <>
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(value) => HandleScheduleTime(value)} />
                            <Link to={``}>
                                <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                                    Schedule Campaign
                                </Button>
                            </Link>
                        </>
                    }
                />
            </Modal>
            {/* model for media file  */}
            <Modal
                title="Select Media"
                open={openMediaModel}
                onCancel={handleCancelMediaModel}
                footer={null}
                width={1200}
            >
                <MediModel HandleUploadImage={HandleUploadImage} />
            </Modal>

            <Modal
                title={
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {progress === 100 ? "Campaign Execution Completed" : "Campaign Execution Under Process"}
                    </div>
                }
                open={isModalVisible}
                footer={null}
                closable={false}
            // onCancel={() => setIsModalVisible(false)}
            // closable={progress === 100}
            >
                <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                    <Progress type="circle" percent={Math.round(progress)} strokeColor={conicColors} />
                    {progress === 100 && (
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="mr-2" onClick={() => resetCampaign()}>New Campaign</Button>
                            <Button onClick={() => navigate('/campaign-history')}>View Report</Button>
                        </div>
                    )}
                </div>
            </Modal>
            <Row gutter={12}>

                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Card title="Carousel Preset">
                                {/* Error Alert */}
                                {error && (
                                    <Col span={24}>
                                        <motion.div
                                            initial={{ opacity: 0, marginBottom: 0 }}
                                            animate={{ opacity: error ? 1 : 0, marginBottom: error ? 20 : 0 }}
                                        >
                                            <Alert type="error" showIcon message={JSON.stringify(error)} />
                                        </motion.div>
                                    </Col>
                                )}
                                <Alert
                                    message="Please select a template without dynamic values"
                                    banner
                                    closable
                                />
                                {/* Form */}
                                <Form
                                    name="basicInformation"
                                    labelCol={{ span: 8 }}
                                    // wrapperCol={{ span: 16 }}
                                    layout="vertical"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Row gutter={[16, 16]}>
                                        {/* Campaign Name */}
                                        <Col span={8}>
                                            <Form.Item
                                                label="Preset Name"
                                                name="Preset-Name"
                                                rules={[{ required: true, message: 'Please enter campaign name!' }]}
                                            >
                                                <Input type="text" onChange={(e) => setName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        {/* Template Category */}
                                        <Col span={6}>
                                            <Form.Item label="Category" name="Template_category">
                                                <Radio.Group
                                                    onChange={(e) => setTemplateCategory(e.target.value)}
                                                    value={templateCategory}
                                                >
                                                    <Radio value="MARKETING">Marketing</Radio>
                                                    <Radio value="UTILITY">Utility</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        {/* Templates Selection */}
                                        {templateCategory && (
                                            <Col span={8}>
                                                <Form.Item label="Templates" name="Templates">
                                                    <Select
                                                        size="default"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => HandleTemplate(value)}
                                                        showSearch
                                                    >
                                                        <Select.Option value="choose...">Select</Select.Option>
                                                        {templateList?.length > 0 ? (
                                                            templateList.map((item, index) => (
                                                                <Select.Option value={item.name} key={index}>
                                                                    {item.name}
                                                                </Select.Option>
                                                            ))
                                                        ) : (
                                                            <Select.Option value="">
                                                                You Don't Have Any Approved Templates
                                                            </Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}
                                        {/* Carousel Cards */}
                                        {cardData.length > 0 && (
                                            <Col span={24}>
                                                <Card title={`Total Cards : ${cardData?.length}`} style={{ width: '100%' }}>
                                                    <Tabs
                                                        defaultActiveKey="0"
                                                        type="card"
                                                        items={
                                                            cardData.map((card, cardIndex) => ({
                                                                key: String(cardIndex),
                                                                label: (
                                                                    `Card ${cardIndex + 1}`
                                                                    // <span style={{ color: tabColors[cardIndex] || 'defaultColor' }}>
                                                                    // </span>
                                                                ),
                                                                children: (
                                                                    <Card bordered={false} style={{ height: '100%' }}>
                                                                        {card.mediaType === 'Media' ? (
                                                                            <Form.Item
                                                                                label={`Select Media File For Card ${cardIndex + 1}`}
                                                                                name={`media-link-${cardIndex}`}
                                                                            >
                                                                                {!card.mediaLink ? (
                                                                                    <Button
                                                                                        style={buttonStyle}
                                                                                        onClick={() => showMediaModel(cardIndex)}
                                                                                    >
                                                                                        <CloudUploadOutlined /> Choose File
                                                                                    </Button>
                                                                                ) : (
                                                                                    <>
                                                                                        <Input
                                                                                            type="text"
                                                                                            disabled
                                                                                            value={card.mediaLink}
                                                                                            className="mb-3"
                                                                                        />
                                                                                        <Button
                                                                                            style={buttonStyle}
                                                                                            onClick={() => showMediaModel(cardIndex)}
                                                                                        >
                                                                                            <CloudUploadOutlined /> Choose Another File
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <Form.Item
                                                                                label="Your media file link"
                                                                                name={`media-link-${cardIndex}`}
                                                                                rules={[
                                                                                    { required: true, message: 'Please enter a media link!' }
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        handleMediaLinkChange(e.target.value, cardIndex)
                                                                                    }
                                                                                    className="mb-3"
                                                                                />
                                                                            </Form.Item>
                                                                        )}
                                                                    </Card>
                                                                ),
                                                            }))}
                                                    />
                                                </Card>
                                            </Col>
                                        )}
                                        {/* Submit Buttons */}
                                        <Col span={24} className="mt-3 d-flex justify-content-end">
                                            <Form.Item>
                                                <Flex alignItems="center" gap={16}>
                                                    {Permisson?.includes('Create Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => ParepareRequest(e)}
                                                            loading={loading}
                                                        >
                                                            Save Preset
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card title="Campaign Preview">
                                <CarouselPreview
                                    mainBody={mainBody}
                                    mediaLink={mediaLink}
                                    requestType={'template'}
                                    cards={cards}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    urlButtons={urlButtons}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
            </Row >
        </>
    )
}

export default CarouselCampaign
