import React, { useRef, useState, useEffect } from 'react';
import { Avatar, Divider, Input, Form, Button, Menu, Tooltip, Spin } from 'antd';
import { SendOutlined, ReloadOutlined, AudioMutedOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Flex from 'components/shared-components/Flex';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import bg from '../../../../assets/image/web-chat.png';
import moment from 'moment';

const menu = () => (
    <Menu>
        <Menu.Item key="0">
            <UserOutlined />
            <span>User Info</span>
        </Menu.Item>
        <Menu.Item key="1">
            <AudioMutedOutlined />
            <span>Mute Chat</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">
            <DeleteOutlined />
            <span>Delete Chat</span>
        </Menu.Item>
    </Menu>
);

const ChatContentHeader = ({ name, onRefresh }) => (
    <div className="chat-content-header">
        <h4 className="mb-0">{name}</h4>
        <Flex>
            <EllipsisDropdown menu={menu} />
            <Tooltip title="Refresh Chat">
                <ReloadOutlined onClick={onRefresh} />
            </Tooltip>
        </Flex>
    </div>
);

const ChatContentBody = ({ messages, loading }) => {
    const chatBodyRef = useRef();
    const styles = {
        chatBackground: {
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        chatBubble: {
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            position: 'relative',
        },
        oppositeBubble: {
            backgroundColor: '#ffff',
            color: '#000',
            marginLeft: '20px',
        },
        ownBubble: {
            backgroundColor: '#22af4b',
            color: '#fff',
            marginRight: '20px',
            alignSelf: 'flex-end',
        },
        chatTime: {
            textAlign: 'right',
            fontSize: '11px',
            color: 'gray',
        },
        dateHeader: {
            textAlign: 'center',
            margin: '10px 0',
            fontSize: '12px',
            color: '#888',
            fontWeight: 'bold',
        },
    };

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollToBottom();
        }
    }, [messages]);

    const formatDate = (dateString) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const messageDate = moment(dateString).startOf('day');

        if (messageDate.isSame(today, 'day')) {
            return 'Today';
        } else if (messageDate.isSame(yesterday, 'day')) {
            return 'Yesterday';
        } else {
            return messageDate.format('MMMM D, YYYY');
        }
    };

    const groupMessagesByDate = (messages) => {
        const groupedMessages = {};
        messages.forEach((message) => {
            const date = formatDate(message.time || message.created_at);
            if (!groupedMessages[date]) {
                groupedMessages[date] = [];
            }
            groupedMessages[date].push(message);
        });
        return groupedMessages;
    };

    const groupedMessages = groupMessagesByDate(messages);

    return (
        <div className="chat-content-body" style={styles.chatBackground}>
            {loading ? (
                <div className="text-center">
                    <Spin size='medium' />
                </div>
            ) : (
                <Scrollbars ref={chatBodyRef} autoHide>
                    {Object.keys(groupedMessages).map((date, index) => (
                        <React.Fragment key={index}>
                            <Divider style={styles.dateHeader}>{date}</Divider>
                            {groupedMessages[date].map((elm, i) => (
                                <div
                                    key={`msg-${elm.id}-${i}`}
                                    className={`msg ${elm.from === 'opposite' ? 'msg-recipient' : elm.from === 'me' ? 'msg-sent' : ''}`}
                                >
                                    {elm.avatar && (
                                        <div className="mr-2">
                                            <Avatar src={elm.avatar} />
                                        </div>
                                    )}
                                    {elm.text && (
                                        <div style={{ ...styles.chatBubble, ...(elm.from === 'opposite' ? styles.oppositeBubble : styles.ownBubble) }}>
                                            <span>{elm.text}</span>
                                            <div style={styles.chatTime} className={`${elm.from === 'me' && 'text-white'}`}>{moment(elm.time || elm.created_at).format('HH:mm')}</div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </Scrollbars>
            )}
        </div>
    );
};

const ChatContentFooter = ({ onSend, disable ,form }) => {
    return (
        <div className="chat-content-footer">
            <Form form={form} name="msgInput" onFinish={onSend} className="w-100">
                <Form.Item name="newMsg" className="mb-0">
                    <Input
                        autoComplete="off"
                        placeholder="Type a message..."
                        suffix={
                            <div className="d-flex align-items-center">
                                <Button shape="circle" type="primary" size="small" htmlType="submit" disabled={disable}>
                                    <SendOutlined />
                                </Button>
                            </div>
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

const Conversation = () => {
    const { api, UserData, authToken, apiKey } = useMyContext();
    const params = useParams();
    const [info, setInfo] = useState({});
    const [msgList, setMsgList] = useState([]);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = params;

    const [form] = Form.useForm();

    const getChats = async (number) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}message/${number}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const chats = response.data;
            setInfo(chats);
            setMsgList(chats?.msg);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getChats(getUserId());
    }, [id]);

    const getUserId = () => {
        return parseInt(id);
    };

    const onSend = async (values) => {
        // console.log(apiKey);
        // return
        if (values.newMsg) {
            try {
                setDisable(true);
                const sendRes = await axios.post(`https://waba.smsforyou.biz/api/send-messages?apikey=${apiKey}&to=${info?.number}&type=C&message=${values.newMsg}`);
                if (sendRes?.status === 200) {
                    const response = await axios.post(`${api}new-chat/${UserData.id}`, {
                        message: values.newMsg,
                    }, {
                        headers: {
                            Authorization: "Bearer " + authToken,
                        },
                    });
                    if (response.data.status) {
                        const newMsgData = {
                            avatar: "",
                            from: "me",
                            msgType: "text",
                            text: values.newMsg,
                            time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        };
                        setMsgList([...msgList, newMsgData]);
                        form.resetFields();
                        setDisable(false);
                    }
                }
            } catch (error) {
                setDisable(false);
                console.log(error);
            }

            form.resetFields();
        }
    };

    return (
        <div className="chat-content">
            <ChatContentHeader name={info?.name} onRefresh={() => getChats(id)} />
            <ChatContentBody messages={msgList} loading={loading} />
            <ChatContentFooter form={form} onSend={onSend} disable={disable} />
        </div>
    );
};

export default Conversation;
